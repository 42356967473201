import { Site } from './site';

export class Resource {
  id: number;
  description: string;
  name: string;
  nameurl: string;
  type: string;
  place: string;
  site: Site = new Site();
  lock: boolean;

  constructor(json?: any) {
    if (json) {
      this.id = json.id;
      this.name = json.name;
      this.nameurl = json.nameurl;
      this.description = json.description;
      this.type = json.type;
      this.place = json.place;
      this.site = new Site(json.site);
      this.lock = json.lock;
    }
  }
}
