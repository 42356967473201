import {Resource} from '../../model/resource';
import {TimeSlot} from './time-slot';
import {User} from '../../model/user';
import {ReservationType} from './reservation-type';

/**
 * Created by dgo on 10.07.17.
 */
export class Reservation {
  id: number;
  start: number;
  end: number;
  resources: Resource[];
  ecawin: string;
  state: string;
  eventName: string;
  eventResponsible: string;
  resourceAddons: string;
  timeSlots: TimeSlot[];
  creator: User;
  communications: any[];
  apr: number;

  conflicts: any[];

  reservationType: ReservationType;

  constructor(json) {
    this.resources = [];
    this.timeSlots = [];
    this.conflicts = [];
    this.creator = new User(null);

    if (json) {
      this.id = json.id;
      this.start = json.start;
      this.end = json.end;
      this.resources = json.resources;
      this.ecawin = json.ecawin;
      this.state = json.state;
      this.eventName = json.eventName;
      this.eventResponsible = json.eventResponsible;
      this.resourceAddons = json.resourceAddons;
      this.timeSlots = json.timeSlots;
      this.creator = new User(json.creator);
      this.communications = json.communications;
      this.apr = json.apr;

      let reservationType = json.reservationType;
      if (reservationType) {
        this.reservationType = new ReservationType(reservationType);
      }
    }
  }

  isSameDay(selected?: TimeSlot) {
    if (!selected) {
      return false;
    }

    let selectedStartDate = new Date(selected.start.getTime());
    selectedStartDate.setHours(0, 0, 0, 0);
    let selectedEndDate = new Date(selected.end.getTime());
    selectedEndDate.setHours(0, 0, 0, 0);

    if (this.start) {
      let conflictDateStart = new Date(this.start);
      conflictDateStart.setHours(0, 0, 0, 0);
      let conflictEnd = new Date(this.end);
      conflictEnd.setHours(0, 0, 0, 0);

      return (
        conflictDateStart.getTime() <= selectedEndDate.getTime() &&
        conflictEnd.getTime() >= selectedStartDate.getTime()
      );
    }
  }

  isEndDayDifferentFromStartDay() {
    let dayOfStart = new Date(this.start);
    dayOfStart.setHours(0, 0, 0, 0);
    let dayOfEnd = new Date(this.end);
    dayOfEnd.setHours(0, 0, 0, 0);

    return dayOfStart.getTime() !== dayOfEnd.getTime();
  }
}
