export class ReservationType {
  id: number;
  name: string;
  backgroundColor: string;
  foregroundColor: string;
  system: any;
  key: string;

  constructor(json: any) {
    if (json) {
      this.id = json.id;
      this.name = json.name;
      this.backgroundColor = json.backgroundColor;
      this.foregroundColor = json.foregroundColor;
      this.system = json.system;
      this.key = json.key;
    }
  }
}
